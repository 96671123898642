export const userInfo = JSON.parse(localStorage.getItem('userInfo'))
// ||
// {
//     email: "",
//     id: "",
//     message: "",
//     photo: "",
//     status: "",
//     token: "",
//     user_name: ""

// }

// console.log(userInfo)