import React from "react";
import "./Chatbox.scss";

export const ChatboxManager = () => {
  return (
    <div className="FAQs">
      <h1 className="faq-header">FAQs</h1>
      <div className="float">
        <div>
          <h4 className="question">
            What’s EduMetrix Full Stack Developer Program?
          </h4>
          <p class="answer">
            EduMetrix provides Full Stack JavaScript training for students from
            engineering and Non-engineering backgrounds and gets them placed in
            Start-ups and MNCs by making them work with our real-life projects
            and enhancing their skills and knowledge which a recruiter looks for
            an ideal candidate.
          </p>
        </div>
        <div>
          <h4 className="question">
            Who All can Apply for EFDP(EduMetrix Full Stack Developer Program)?
          </h4>
          <div>
            <p class="answer">
              Anyone who wants to learn programming and build a career as
              Software Engineer.
            </p>
          </div>
        </div>
        <div>
          <h4 className="question">
            Do I need any Prior Knowledge in Programming?
          </h4>
          <div>
            <p class="answer">
              No, We will groom you from Scratch and make you as a Pro in Just 6
              Months. You be ready to learn with all your dedication.{" "}
            </p>
          </div>
        </div>
        <div>
          <h4 className="question">
            What’s the minimum educational Qualification to Join the EFDP ?{" "}
          </h4>
          <div>
            <p class="answer">
              You must have a degree in any domain to fulfil most of the
              company’s basic hiring criteria.
            </p>
          </div>
        </div>
        <div>
          <h4 className="question">
            What’s the Admission Procedure for the program ?{" "}
          </h4>
          <div>
            <p class="answer">
              1. Contact us in WhatsApp for admission. <br />
              2. Our Executives will Schedule a call and explain you the course
              details <br />
              3. Choose your location to join from available location options
              (Bangalore, Nilambur) <br />
              4. If you are going for Pay After Placement Pay the Admission fees
              Rs. 0/- <br />
              -for Offline Program Visit Branch to make Documentation.
              -for Online Do the Documentation Online Once Eligibility Checking
              get finish within 3 days You will be able to join for the program{" "}
              <br />
              <br />
              6. Pack Your Bag and come join next day or Start Online from Next
              Day.
            </p>
          </div>
        </div>
        <div>
          <h4 className="question">
            What All the Documents Do I need to Submit if I choose Pay After
            Placement ?{" "}
          </h4>
          <div>
            <p class="answer">
              - Adhar Card <br />
              - Pan Card <br />
              - Latest 3 Months Salary Slip <br />
              - Latest 3 Months Bank
              Statement <br />
              - Photograph <br />
              If You are not a Working Person then you need to Submit Documents
              of any family member <br />
              (Blood Relation – Parents/Siblings) as a supporting Document.
            </p>
          </div>
        </div>
        <div>
          <h4 className="question">What’s the Total Course Fees?</h4>
          <div>
            <p class="answer">
              If you are paying Upfront fees you will have to pay only Rs.
              150000/- + GST ,<br />
              or if you are Opting Pay After Placement you will have to pay a
              total of Rs. 150000/- + GST, as 36 EMI es once you get Placed.{" "}
            </p>
          </div>
        </div>
        <div>
          <h4 className="question">
            How much EMI I will have to pay each month once I get placed?{" "}
          </h4>
          <div>
            <p class="answer">Rs.5605/- will be your EMI for 36 Months. </p>
          </div>
        </div>
        <div>
          <h4 className="question">Is my placement is guaranteed? </h4>
          <div>
            <p class="answer">
              EduMetrix ensures 100% Job guaranty once you successfully finish
              your 6months duration, even though your effort in learning and
              performance does matters, if you continuously make poor performance
              then we can’t guaranty your job.{" "}
            </p>
          </div>
        </div>
        <div>
          <h4 className="question">
            What’s the salary package you can offer in job guaranty?{" "}
          </h4>
          <div>
            <p class="answer">
              We assure you minimum package of 3.6LPA , but 99% of our
              candidates got placed above 6LPA{" "}
            </p>
          </div>
        </div>
        <div>
          <h4 className="question">When is the next batch starting? </h4>
          <div>
            <p class="answer">
              We are not a training institute to start batch-wise we are an IT
              firm , we run the program according to the seat availability in
              our branches.{" "}
            </p>
          </div>
        </div>
        <div>
          <h4 className="question">What Do you Teach me as a Student?</h4>
          <div>
            <p class="answer">
              We don’t teach you as students , in-fact we are taking you as our
              interns and make you work on real project guiding you in front
              end, backend , server technologies and train you as a full stack
              developer which a hiring company look for in their ideal
              candidate.{" "}
            </p>
          </div>
        </div>
        <div>
          <h4 className="question">How does your EFDP works?</h4>
          <div>
            <p class="answer">
              1. We take you as our interns and train you on Front End: HTML,
              CSS, JavaScript, React, React Native Back End: JavaScript,
              MySQL/MongoDB, Node Server: Azure Personality Development: Prepare
              you for interviews
              <br />
              2. We give you real project tasks and our FullStack Engineers
              Assist you to finish tasks and make you grab more knowledge in
              real projects and help you grow your problem solving skills
              <br />
              3. 4th Month onwards we will start sending you for interviews
              scheduled with our partnering companies.
              <br />
              4. If you fail in interview, we will analyse the skillset which
              made you fail and we will train you again on that and send you for
              other interview.
              <br />
              5. We make sure to LAND ON A JOB as we promised
            </p>
          </div>
        </div>
        <div>
          <h4 className="question">
            Now Take your Phone and Dial / WhatsApp us
          </h4>
          <div>
            <p class="answer">
              +91 8310 715 970 / +91 9074 851 744 to Know More{" "}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
