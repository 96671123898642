export const internshipdata = [{
    id: "1",
    name: "Shafan",
    phone: "9459088234",
    email: "Shafan@gmail.com",
    place: "Bangalore",
    gender: "Male",
    employe_status: "Employe",
    course: "Full Stack course",
    qualification: "B.Tech",
    year_of_qualification: "2016",
    college: "Fakir Mohan University"
},{
    id: "2",
    name: "Abhishek",
    phone: "8459568234",
    email: "abhishek@gmail.com",
    place: "Lukhnow",
    gender: "Male",
    employe_status: "Employe",
    course: "Bakend course",
    qualification: "Bca",
    year_of_qualification: "2015",
    college: "IPU University"
},{
    id: "3",
    name: "sruti",
    phone: "9834671278",
    email: "sruti@gmail.com",
    place: "Delhi",
    gender: "Female",
    employe_status: "fresher",
    course: "fulstack course",
    qualification: "Bsc",
    year_of_qualification: "2020",
    college: "Delhi University"
}]
